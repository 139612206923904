import React from 'react';
import LogoBare from '../logoBare';
import {header, navigate, logo, logoWrapper} from '../../style/components/_header.module.scss';
import { Link } from 'gatsby';

const Header = () => {
  const activeStyle = {
    fontWeight: '500',
    color: '#CA3332',
  };

  const navigation = [
    { text: "PROJECTS", url: "/projects" },
    { text: "ABOUT US", url: "/about" },
  ];

  return ( 
  <div className={header}>
    <div className={logoWrapper}>
      <div className={logo}>
        <Link to="/">
          <LogoBare size={"2rem"}/>
        </Link>
      </div>
    </div>
    <nav className={navigate}>
      <ul>
        {navigation.map((link, index) => {
          return (
            <li key={index}>
            <Link to={link.url}
              activeStyle={activeStyle}>
              {link.text}
            </Link>
            </li>)
        })}
      </ul>
    </nav>
  </div> );
}
 
export default Header;