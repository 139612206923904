import React from 'react';
import { pageDesktopWrapper } from '../../style/layout/_page.module.scss';
import Header from './header';

const DesktopLayout = ({ children }) => {
  return (
  <>
  <div className={pageDesktopWrapper}>
    <Header/>
    <main>
      {children}
    </main>
  </div>
  </>
  );
}
 
export default DesktopLayout;